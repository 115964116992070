<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"></span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <!-- <span class="h3 text-secondary">Informations générales</span> -->
        <span class="h2 mr-auto text-secondary"> Nouvelle application</span>

        <div class="ml-auto">
          <b-button
            @click="createItem"
            type="submit"
            pill
            :disabled="submitingForm"
          >
            <b-spinner
              small
              v-if="submitingForm"
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
        </div>
      </div>
      <!-- <hr class="mt-1 mb-3 bg-secondary" /> -->
      <div class="d-flex col-12 p-0 m-0 mt-3" style="gap: 5px">
        <div class="col-md-4 shdow row col-12 m-0">
          <div class="col-12 p-0">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
              "
            >
              <span class="h4 text-secondary">Informations générales</span>
            </div>
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>
          <div class="col-12 col-md-12 p-0">
            <b-form-group
              label-cols="4"
              label="Nom:"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                placeholder="Nom"
                v-model="newApplication.name"
                :class="{
                  'is-invalid':
                    $v.newApplication.name.$error &&
                    $v.newApplication.name.$dirty,
                }"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.name.$dirty"
              >
                {{
                  !$v.newApplication.name.required
                    ? "Champ obligatoire"
                    : !$v.newApplication.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newApplication.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>

            <!--  -->
            <b-form-group
              label-cols="4"
              label="Description:"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                type="text"
                placeholder="Description"
                v-model="newApplication.description"
              ></b-form-textarea>
            </b-form-group>

            <!--  -->
            <b-form-group
              label-cols="4"
              label="Libellé court:"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                placeholder="Libellé court"
                v-model="newApplication.libellecour"
                :class="{
                  'is-invalid':
                    $v.newApplication.libellecour.$error &&
                    $v.newApplication.libellecour.$dirty,
                }"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.libellecour.$dirty"
              >
                {{
                  !$v.newApplication.libellecour.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
            </b-form-group>

            <!--  -->
            <b-form-group
              label-cols="4"
              label="Statut:"
              label-class="font-weight-bold"
            >
              <v-select
                type="text"
                placeholder="Statut"
                v-model="newApplication.status"
                :options="['En prod', 'En cours', 'Terminé']"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.status.$error &&
                    $v.newApplication.status.$dirty,
                }"
              ></v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.status.$dirty"
              >
                {{
                  !$v.newApplication.status.required ? "Champ obligatoire" : ""
                }}
              </span>
            </b-form-group>

            <!--  -->

            <b-form-group
              label-cols="4"
              label="Ligne métier:"
              label-class="font-weight-bold"
            >
              <multiselect
                multiple
                label="name"
                track-by="id"
                placeholder="Ligne métier"
                :searchable="true"
                v-model="newApplication.businessLine"
                :options="businessesLigne"
              ></multiselect>
            </b-form-group>

            <!--  -->

            <b-form-group
              label-cols="4"
              label="Type SI:"
              label-class="font-weight-bold"
            >
              <v-select
                type="text"
                placeholder="Type SI"
                v-model="newApplication.typeSi"
                :options="['SI Interne', 'Lorem', 'Ipsum']"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.typeSi.$error &&
                    $v.newApplication.typeSi.$dirty,
                }"
              ></v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.typeSi.$dirty"
              >
                {{
                  !$v.newApplication.typeSi.required ? "Champ obligatoire" : ""
                }}
              </span>
            </b-form-group>
          </div>
          <div class="col-12 col-md-12 p-0">
            <!--  -->

            <b-form-group
              label-cols="4"
              label="Patrimoine:"
              label-class="font-weight-bold"
            >
              <v-select
                type="text"
                placeholder="Patrimoine"
                v-model="newApplication.patrimoine"
                :options="patrimoinesList"
                label="text"
                :reduce="(reduce) => reduce.value"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.patrimoine.$error &&
                    $v.newApplication.patrimoine.$dirty,
                }"
              ></v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.patrimoine.$dirty"
              >
                {{
                  !$v.newApplication.patrimoine.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
            </b-form-group>
            <!--  -->
            <b-form-group
              label-cols="4"
              label="PO:"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="(user) => user.value"
                :options="ownersList"
                placeholder="PO"
                v-model="newApplication.po"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.po.$error && $v.newApplication.po.$dirty,
                }"
              />
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.po.$dirty"
              >
                {{ !$v.newApplication.po.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <!--  -->
            <b-form-group
              label-cols="4"
              label="Type:"
              label-class="font-weight-bold"
            >
              <v-select
                type="text"
                placeholder="Type"
                v-model="newApplication.type"
                :options="['SI Interne', 'Lorem', 'Ipsum']"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.type.$error &&
                    $v.newApplication.type.$dirty,
                }"
              ></v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.type.$dirty"
              >
                {{
                  !$v.newApplication.type.required ? "Champ obligatoire" : ""
                }}
              </span>
            </b-form-group>
            <!--  -->

            <!--  -->
            <b-form-group
              label-cols="4"
              label="Portée:"
              label-class="font-weight-bold"
            >
              <v-select
                type="text"
                placeholder="Portée"
                v-model="newApplication.portee"
                :options="['Local', 'consectetur', 'aut']"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newApplication.portee.$error &&
                    $v.newApplication.portee.$dirty,
                }"
              ></v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApplication.portee.$dirty"
              >
                {{
                  !$v.newApplication.portee.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="mb-0 font-weight-bold">Taux:</label>
              </div>
              <div class="col-md-8">
                <section class="simple-slider col-md-12 p-0">
                  <vue-slide-bar class="pt-3" v-model="newApplication.taux" />
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="shdow col-12 col-md-4 m-0">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h4 text-secondary">Documents</span>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>

          <div class="col-12 m-0 p-0">
            <FilePicker
              @change="filePickerChanger"
              class="font-weight-normal"
            />
          </div>
        </div>
        <div class="shdow col-md-4 col-12 m-0">
          <div>
            <div class="col-12 p-0">
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <span class="h4 text-secondary">Couvertures</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <div class="col-12 p-0">
              <coverage @change="changeCouverture" />
            </div>
          </div>
          <br />
          <div>
            <div class="col-12 p-0">
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <span class="h4 text-secondary">Pays responsables</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <div class="col-12 p-0">
              <PaysResponsable
                :paysResponsableFunction="paysResponsableFunction"
              />
            </div>
            <div class="row m-0 p-0">
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
                v-for="(covert, index) in paysResponable"
                :key="index"
              >
                <p>
                  <country-flag
                    :country="covert.countryCode"
                    size="small"
                    v-show="covert.countryCode"
                  />{{ covert.country }}
                </p>

                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i
                      @click="deletePaysResponsable(index)"
                      class="mdi mdi-close-circle text-danger"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row mt-3">
        <div class="col-12">
          <b-tabs class="tab-solid tab-solid-primary mt-3">
            <b-tab title="Référents">
              <application-referent :referents="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Bénéficiaires">
              <application-payee :beneificiaires="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Capacité">
              <application-capacity :capacites="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Processus supportés">
              <application-process :processus="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Fonctions">
              <application-function :fonctions="[]" :disabled="true" />
            </b-tab>
            <b-tab title="API exposées">
              <application-api :apis="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Données & GDPR">
              <application-data :donnees="[]" :disabled="true" />
            </b-tab>

            <b-tab title="Composants techniques">
              <application-technical-component
                :composants="[]"
                :disabled="true"
              />
            </b-tab>

            <b-tab title="Flux et échanges">
              <application-exchange :fluxs="[]" :disabled="true" />
            </b-tab>

            <b-tab title="Transformations">
              <application-transformation
                :transformations="[]"
                :disabled="true"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import ApplicationReferent from "./components/ApplicationReferent.vue";
import ApplicationPayee from "./components/ApplicationPayee.vue";
import ApplicationCapacity from "./components/ApplicationCapacity.vue";
import ApplicationProcess from "./components/ApplicationProcess.vue";
import ApplicationFunction from "./components/ApplicationFunction.vue";
import ApplicationApi from "./components/ApplicationApi.vue";
import ApplicationData from "./components/ApplicationData.vue";
import ApplicationTechnicalComponent from "./components/ApplicationTechnicalComponent.vue";
import ApplicationExchange from "./components/ApplicationExchange.vue";
import ApplicationTransformation from "./components/ApplicationTransformation.vue";
import Coverage from "./components/Coverage.vue";
import CountryFlag from "vue-country-flag";
import VueSlideBar from "vue-slide-bar";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
import { required, minLength } from "vuelidate/lib/validators";
import PaysResponsable from "../../../components/custom/PaysResponsable";
import FilePicker from "../../../components/custom/FilePicker"; //validation

import { USER_URL, APPLICATION_URL } from "@/helpers/constURL.js";
export default {
  components: {
    FilePicker,
    CountryFlag,
    PaysResponsable,
    VueSlideBar,
    ModalActions,
    ApplicationReferent,
    ApplicationPayee,
    ApplicationCapacity,
    ApplicationProcess,
    ApplicationFunction,
    ApplicationApi,
    ApplicationData,
    ApplicationTechnicalComponent,
    ApplicationExchange,
    ApplicationTransformation,
    Coverage,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    paysResponable: [],
    livrables: [],
    newApplication: {
      name: "",
      description: "",
      patrimoine: "", //
      libellecour: "",
      po: "",
      refSa: "",
      taux: 0, //
      status: "", //
      typeSi: "",
      type: "",
      businessLine: [],
    },
    couvertures: [],
  }),
  validations: {
    newApplication: {
      name: {
        required,
        minLength: minLength(5),
      },
      libellecour: {
        required,
      },
      status: {
        required,
      },
      typeSi: {
        required,
      },
      patrimoine: {
        required,
      },
      po: {
        required,
      },
      type: {
        required,
      },
      portee: {
        required,
      },
    },
  },

  methods: {
    changeCouverture(couvertures) {
      this.couvertures = couvertures;
    },
    filePickerChanger(files) {
      this.livrables = files;
    },
    deletePaysResponsable(index) {
      this.paysResponable.splice(index, 1);
    },
    paysResponsableFunction(location) {
      (!this.paysResponable.find(
        (pays) => pays.countryCode === location.countryCode
      ) &&
        this.paysResponable.push(this.clone(location))) ||
        Swal.fire("Impossible d'ajouter un pays déjà existant !", "", "error");
    },
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    createItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store
          .dispatch("application/createApplication", {
            ...this.newApplication,
            name: this.newApplication.name,
            libellecour: this.newApplication.libellecour,
            description: this.newApplication.description,
            status: this.newApplication.status,
            po: this.newApplication.po,
            refSa: this.newApplication.refSa,
            patrimoine: this.newApplication.patrimoine
              ? this.newApplication.patrimoine
              : null,
            typeSi: this.newApplication.typeSi,
            type: this.newApplication.type,
            protee: this.newApplication.protee,
            taux: this.newApplication.taux,
            couversturesResp: this.paysResponable
              ? this.paysResponable.map((element) => ({ ...element }))
              : [],
            businessLine: this.newApplication.businessLine.map(
              (ligne) => ligne["@id"]
            ),
            validateDate: moment().format("DD/MM/YYYY"),
          })
          .then(() => {
            this.submitingForm = false;
          });
      }
    },
  },
  created() {
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("businessLine/fetchAllBusinessLines");
  },
  computed: {
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("application", ["APPLICATION", "APPLICATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),

    capaciteRatechements() {
      return this.CAPACITE_METIERS;
    },
    applicationsList() {
      return this.APPLICATIONS;
    },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    ownersList() {
      return this.RESPONSABLES.map((responsable) => ({
        text: `${responsable.firstname} ${responsable.lastname}`,
        value: `/api/${USER_URL}/${responsable.id}`,
      }));
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        text: organisation.libelle,
        value: organisation["@id"],
      }));
    },
    businessesLigne() {
      return this.BUSINESS_LINES;
    },
  },
  watch: {
    APPLICATION() {
      this.submitingForm = false;
      this.couvertures.forEach((element) => {
        this.$store.dispatch("couverture/createCouverture", {
          ...element,
          application: `/api/${APPLICATION_URL}/${this.APPLICATION.id}`,
        });
      });

      this.livrables.map((file) => {
        file.append("application", this.APPLICATION["@id"]);
        this.$store.dispatch("application/createFileApplication", file);
      });

      this.$store.dispatch("referent/createReferent", {
        application: this.APPLICATION["@id"],
        user: this.APPLICATION.po["@id"],
        role: "PO/CDP",
      });
      Swal.fire({
        title: "L'application est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-application",
            params: {
              slug: this.APPLICATION.slug,
              id: this.APPLICATION.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
